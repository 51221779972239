.nav-menu {
	ul {
		padding-left: 10px;
	}

	li a {
		&.ancestor {
			font-weight: bold;
		}

		&.selected {
			color: #000;
			font-weight: bold;
			text-decoration: none;
		}
	}
}

.nav-menu-style-arrows ul {
	list-style-image: url(../images/arrows/04_right.png);
}

.portlet-navigation {
	.nav-pills > li {
		> a {
			margin-bottom: 2px;
		}

		> .dropdown-menu {
			margin-top: 0;
		}
	}
}

.split-button-dropdowns {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		display: inline-block;
		padding-left: 2px;
		padding-right: 2px;
	}
}