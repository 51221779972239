.hide-accessible {
	clip: rect(0 0 0 0) !important;
	position: absolute !important;

	/*Webkit and IE7 let clipped content change the scroll height of the page*/
	/*Scale webkit to 0*/
	-webkit-transform: scale(0);
	-webkit-transform-origin-x: 0;
	-webkit-transform-origin-y: 0;
	/*Change IE7 to using fixed*/
	*position: fixed !important;
	/*...And reset back to absolute for IE6*/
	_position: absolute !important;
}

.force-offset {
	display: block !important;
	position: absolute !important;
	visibility: hidden !important;
}

.unselectable, .unselectable * {
	@include user-select(none);
}