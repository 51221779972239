a.taglib-icon {
	&, &:focus, &:hover {
		text-decoration: none;
	}

	&:focus, &:hover {
		.taglib-text {
			text-decoration: underline;
		}
	}

	&.btn {
		&:focus, &:hover {
			.taglib-text {
				text-decoration: none;
			}
		}
	}
}

.taglib-icon[lang] > img {
	vertical-align: baseline;
	width: 16px;
}

.taglib-icon-label {
	margin-left: 12px;
}