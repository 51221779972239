.lfr-upload-container {
	.upload-target {
		border-color: #DDD;

		h4 span {
			text-transform: lowercase;
		}

		.drop-file-text {
			font-weight: normal;
		}
	}

	a {
		&.browse-button {
			background-image: url(../images/common/add.png);
			background-repeat: no-repeat;
			color: white;
			font-size: 1.2em;
			font-weight: bold;
			text-decoration: none;
		}

		&.clear-uploads {
			background-image: url(../images/common/remove.png);
			background-repeat: no-repeat;
		}

		&.cancel-uploads {
			background-image: url(../images/common/close.png);
			background-repeat: no-repeat;
		}
	}

	.multiple-files .upload-file {
		&.upload-complete {
		}
	}

	.upload-file {
		.file-title {
		}
	}

	.upload-list-info h4 {
		font-size: 1.3em;
	}

	.cancel-button {
		color: #869CAD;
	}

	.delete-button {
		color: #869CAD;
	}

	.file-added .success-message {
		font-weight: normal;
	}

	.upload-error {
		opacity: 1;
	}

	.multiple-files .upload-error {
		background: #FDD url(../images/messages/error.png) no-repeat 5px 5px;
		border-color: #900;
		color: #900;
		font-weight: normal;
	}

	.upload-complete {
		.error-message, .success-message {
			font-weight: bold;
		}
	}

	.file-uploading {
		background-color: #FFC;
	}

	.progress-bar {
		background: #FFF;
		margin-top: 25px;
		text-align: left;
		width: 100%;
	}

	.progress {
		background: #8DB2E3;
	}
}

.upload-drop-active .lfr-upload-container .upload-target {
	background-color: #DDEDDE;
	border-color: #7D7;
	border-style: dashed;
}