.info-panel .panel-group {
	margin-left: -$sidebar-padding-left;
	margin-right: -$sidebar-padding-right;
}

.lfr-panel-container {
	background: #FFF;
}

.lfr-panel-content {
	padding: 5px 10px;
}

.lfr-panel-basic .lfr-panel-content {
	padding-left: 15px;
}

.panel-hd {
	background: #C0C2C5 url(../images/application/panel_header_bg.png) repeat-x 0 0;
	border: 1px solid;
	border-color: #C8C9CA #AEB2B8 #AEB2B8 #C8C9CA;
	padding-left: 10px;
	text-shadow: 1px 1px #FFF;
}