/* ---------- Standard styles defined in the JSR 168 specification ---------- */

/* ---------- Fonts ---------- */

.portlet-font {
}

.portlet-font-dim {
}

/* ---------- Sections ---------- */

.portlet-section-selected {
	&, a {
		background: #727C81;
		color: #FFF;
		font-weight: bold;
		text-decoration: none;
	}

	a:hover, a:focus {
		background-color: #727C81;
	}
}

.portlet-section-subheader {
}

.portlet-section-footer {
}

.portlet-section-text {
}

/* ---------- Forms ---------- */

.portlet-form-label {
}

.portlet-form-input-field {
}

.portlet-form-button {
}

.portlet-form-field-label {
}

.portlet-form-field {
}

/* ---------- Icons ---------- */

.portlet-icon-label {
}

.portlet-dlg-icon-label {
}

/* ---------- Menus ---------- */

.portlet-menu {
}

.portlet-menu-item {
}

.portlet-menu-item-selected {
}

.portlet-menu-item-hover {
}

.portlet-menu-item-hover-selected {
}

.portlet-menu-cascade-item {
}

.portlet-menu-cascade-item-selected {
}

.portlet-menu-description {
}

.portlet-menu-caption {
}