#wrapper {
	padding: 0;
}

.product-menu {
	.panel-header, .panel-heading, .nav, .sidebar-header {
		font-size: 1.4rem !important;
	}
}

.ib4 {
	.container {
		&::before, &::after {
			display: table;
			content: " ";
		}

		&::after {
			clear: both;
		}
	}
}