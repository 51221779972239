.popover {
	max-width: 310px;

	.ib4 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.popover-header {
		background-color: white;
		border-bottom: 2px solid #cbcccc;
		color: #595959;
		font-size: 2.2rem;
		margin-bottom: 0;
		padding: 1.6rem;
		padding-right: 4.5rem;
	}

	.popover-body {
		padding: 1.6rem;

		p {
			margin: 0.65rem;
		}

		small {
			font-size: 85%;
		}
	}
}