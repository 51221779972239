.dragging .portlet-column.customizable {
	background-color: #CFFCCF;
	outline: 3px solid #5EAF3D;
}

.customizable-layout-column-content {
	&.yui3-widget-content-expanded {
		margin: 0 15px;
	}

	background-color: #C92C3C;
}

.layout-customizable-controls {
	background-color: rgba(201, 44, 60, 0.8)
}

.customizable {
	.customizable-layout-column-content {
		background-color: #5EAF3D;
	}

	.layout-customizable-controls {
		background-color: rgba(94, 175, 61, 0.8);
	}
}

.layout-customizable-controls-container {
	margin: 0 15px;
	position: relative;
}

.layout-customizable-controls {
	label {
		color: #FFF;
	}

	font-size: 1.5em;
	padding: 4px 16px;
	position: absolute;
	width: 100%;
	z-index: 15;
}