.lfr-autocomplete-input-list .yui3-aclist-list {
	margin: 0;
}

.liferay-autocomplete-input-mirror {
	left: -9999px;
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	top: -9999px;
	visibility: hidden;
	white-space: pre-wrap;
	word-wrap: break-word;
}