.lfr-search-container-wrapper {
	&.lfr-search-container-fixed-first-column {
		position: relative;

		.table-responsive {
			@include media-breakpoint-up(sm) {
				margin-left: $table-responsive-margin-left-md;
				width: auto;
			}

			.table {
				position: static;

				.lfr-search-iterator-fixed-header {
					left: 12px;
					position: fixed;
					right: 12px;
					top: -1px;
					z-index: $zindex-sticky;

					& > th {
						display: block;
						padding: 0;

						.lfr-search-iterator-fixed-header-inner-wrapper {
							overflow-x: hidden;

							@include media-breakpoint-up(sm) {
								margin-left: $table-responsive-margin-left-md;
							}

							table {
								border-collapse: collapse;
								width: 100%;

								th {
									border-radius: 0;
								}
							}
						}
					}
				}

				td,
				th {
					width: auto;

					&:first-child {
						@include media-breakpoint-up(sm) {
							left: 0;
							position: absolute;
							right: 15px;
						}
					}
				}

				th {
					height: auto;
				}
			}
		}
	}

	a {
		color: $body-color;
	}

	.lfr-icon-menu > .dropdown-toggle {
		color: $secondary;

		&:active, &:focus, &:hover {
			background-color: #F7F8F9;
			border-radius: 4px;
			color: $body-color;
		}
	}

	.list-group {
		margin-bottom: 0;

		.list-group-item {
			&:nth-last-child(2) {
				@include border-bottom-radius($list-group-border-radius);
			}

			h4 {
				font-size: $font-size-sm;
				line-height: 1.5;
				margin-bottom: 0;
			}

			h5, h6, .h5, .h6 {
				font-size: $font-size-sm;
				font-weight: 400;
				line-height: 1.5;
				margin-bottom: 0;
			}

			h6 + h5 {
				font-weight: 600;
			}

			.checkbox {
				margin-top: 0;
			}
		}

		& + .taglib-search-iterator-page-iterator-bottom {
			margin-top: 20px;
		}
	}

	@if $enable-rounded {
		.table-list {
			tbody {
				tr:nth-last-child(2) {
					td, th {
						&:first-child {
							border-bottom-left-radius: $table-list-border-radius;
						}

						&:last-child {
							border-bottom-right-radius: $table-list-border-radius;
						}
					}
				}
			}
		}
	}
}

.lfr-asset-type-column, .lfr-author-column, .lfr-city-column, .lfr-country-column, .lfr-email-address-column, .lfr-job-title-column, .lfr-region-column, .lfr-removed-by-column, .lfr-replier-column, .lfr-scope-column, .lfr-screen-name-column, .lfr-type-column, .lfr-user-column, .lfr-users-column {
	word-break: break-all \9;
	word-wrap: break-word;

	@include sm {
		max-width: 125px;
		min-width: 100px;
	}
}

.lfr-completion-date-column, .lfr-create-date-column, .lfr-date-column, .lfr-display-date-column, .lfr-expiration-date-column, .lfr-last-post-date-column, .lfr-modified-date-column, .lfr-removed-date-column, .lfr-reply-date-column, .lfr-revision-column, .lfr-size-column {
	white-space: nowrap;
}

.lfr-description-column, .lfr-role-column {
	max-width: $table-responsive-margin-left-md;
	min-width: $table-responsive-margin-left-md;
}

.lfr-email-address-column, .lfr-name-column, .lfr-parent-organization-column, .lfr-screen-name-column, .lfr-title-column, .lfr-url-column, .lfr-wiki-column {
	max-width: 200px;
	min-width: 200px;
}

.lfr-email-column, .lfr-mobile-column, .lfr-website-column {
	min-width: 100px;

	@include sm {
		min-width: 115px;
	}

	.checkbox-container .form-group {
		margin-bottom: 0;
	}
}

.user-info .user-details {
	.date-info {
		color: #8C8C8C;
	}

	.user-name {
		color: #009AE5;
	}
}