.taglib-diff-html {
	div {
		&.diff-removed-image {
			background: #FDC6C6 url(../images/diff/minus.png);
		}

		&.diff-added-image {
			background: #CFC url(../images/diff/plus.png) no-repeat;
		}

		&.diff-removed-image, &.diff-added-image {
			opacity: 0.55;
		}
	}

	span {
		&.diff-html-added {
			background-color: #CFC;
			font-size: 1em;

			img {
				border-color: #CFC;
			}
		}

		&.diff-html-changed {
			background: url(../images/diff/underline.png) bottom repeat-x;

			img {
				border-color: #009;
			}
		}

		&.diff-html-removed {
			background-color: #FDC6C6;
			font-size: 1em;
			text-decoration: line-through;

			img {
				border-color: #FDC6C6;
			}
		}
	}
}