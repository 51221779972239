/* ---------- Cards ---------- */

$card-tab-border-left-color: #65B6F0;
$card-tab-border-left-width: 4px;
$card-tab-padding-left-width: 20px;

/* ---------- Cards horizontal ---------- */

$card-horizontal-on-border: #65B6F0 !default;
$card-horizontal-on-border-width: 2px !default;

/* ---------- Dialogs ---------- */

$dialog-footer-bg: #FFF !default;
$dialog-footer-border-color: $modal-footer-border-color !default;
$dialog-footer-box-shadow: if(variable-exists(modal-footer-box-shadow), $modal-footer-box-shadow, null) !default;
$dialog-footer-height: 60px;
$dialog-navbar-height: 48px !default;

$dialog-desktop-footer-bg: if(variable-exists(modal-desktop-footer-bg), $modal-desktop-footer-bg, $dialog-footer-bg) !default;
$dialog-desktop-footer-height: 80px;
$dialog-desktop-navbar-height: if(variable-exists(navbar-desktop-height), $navbar-desktop-height, $dialog-navbar-height) !default;

$dialog-footer-padding: 20px !default;
$dialog-iframe-padding: 0 !default;

/* ---------- Splitter ---------- */

$splitter-bg: map-get($panel-secondary, header-bg) !default;
$splitter-border-radius: nth($panel-border-radius, 1) nth($panel-border-radius, 1) 0 0 !default;
$splitter-box-shadow: if(variable-exists(panel-box-shadow), $panel-box-shadow, null) !default;
$splitter-footer-margin: 20px;
$splitter-padding: 10px 15px !default;
$splitter-spaced-border-radius: $panel-border-radius;
$splitter-text-color: map-get($panel-secondary, color) !default;

/* ---------- Toggle card ---------- */

$toggle-card-border-radius: 2px !default;

$toggle-card-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15) !default;

$toggle-card-icon-border-radius: 30px !default;
$toggle-card-icon-height: 32px !default;
$toggle-card-icon-width: 32px !default;

$toggle-card-border: #DCE0E3 !default;
$toggle-card-border-width: 1px !default;
$toggle-card-icon-bg: #FFF !default;
$toggle-card-icon-border: #869CAD !default;
$toggle-card-icon-border-width: 0 !default;

$toggle-card-on-border: #65B6F0 !default;
$toggle-card-on-border-width: 2px !default;
$toggle-card-on-icon-bg: #FFF !default;
$toggle-card-on-icon-border: #65B6F0 !default;
$toggle-card-on-icon-border-width: 0 !default;

/* ---------- File icons colors ---------- */

$file-icon-color-0: #6B6C7E !default;
$file-icon-color-1: #A7A9BC !default;
$file-icon-color-2: #50D2A0 !default;
$file-icon-color-3: #AF78FF !default;
$file-icon-color-4: #FFB46E !default;
$file-icon-color-5: #FF5F5F !default;
$file-icon-color-6: #4B9BFF !default;
$file-icon-color-7: #272833 !default;

/* ---------- File icons text colors ---------- */

$file-icon-text-color-0: #FFF !default;
$file-icon-text-color-1: #FFF !default;
$file-icon-text-color-2: #FFF !default;
$file-icon-text-color-3: #FFF !default;
$file-icon-text-color-4: #FFF !default;
$file-icon-text-color-5: #FFF !default;
$file-icon-text-color-6: #FFF !default;
$file-icon-text-color-7: #FFF !default;

/* ---------- User icons background colors ---------- */

$user-icon-bg-color-0: #FFF !default;
$user-icon-bg-color-1: #FFF !default;
$user-icon-bg-color-2: #FFF !default;
$user-icon-bg-color-3: #FFF !default;
$user-icon-bg-color-4: #FFF !default;
$user-icon-bg-color-5: #FFF !default;
$user-icon-bg-color-6: #FFF !default;
$user-icon-bg-color-7: #FFF !default;
$user-icon-bg-color-8: #FFF !default;
$user-icon-bg-color-9: #FFF !default;

/* ---------- User icons border color ---------- */

$user-icon-border-color-0: #E7E7ED !default;
$user-icon-border-color-1: #E7E7ED !default;
$user-icon-border-color-2: #E7E7ED !default;
$user-icon-border-color-3: #E7E7ED !default;
$user-icon-border-color-4: #E7E7ED !default;
$user-icon-border-color-5: #E7E7ED !default;
$user-icon-border-color-6: #E7E7ED !default;
$user-icon-border-color-7: #E7E7ED !default;
$user-icon-border-color-8: #E7E7ED !default;
$user-icon-border-color-9: #E7E7ED !default;

/* ---------- User icons colors ---------- */

$user-icon-color-0: #008576 !default;
$user-icon-color-1: #287D3C !default;
$user-icon-color-2: #2E5AAC !default;
$user-icon-color-3: #DA1414 !default;
$user-icon-color-4: #007DAB !default;
$user-icon-color-5: #272833 !default;
$user-icon-color-6: #6B6C7E !default;
$user-icon-color-7: #B95000 !default;
$user-icon-color-8: #BE3370 !default;
$user-icon-color-9: #9C33BE !default;