%display-portlet-topper {
	@include media-query(sm) {
		opacity: 1;

		@include transform(translateY(-97%));
	}
}

%display-portlet-content-editable {
	@include media-query(sm) {
		border-color: $portlet-topper-border;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.portlet {
	margin-bottom: 10px;
	position: relative;

	> .portlet-content-editable {
		border-color: transparent;
	}

	> .portlet-topper {
		display: none;

		@include media-query(sm) {
			@include display(flex);

			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;

			@include transition(opacity 0.25s, transform 0.25s);

			top: 0;
		}
	}

	&.open {
		> .portlet-content-editable {
			@extend %display-portlet-content-editable;
		}

		> .portlet-topper {
			@extend %display-portlet-topper;
		}
	}
}

.portlet {
	&:hover, &.focus {
		> .portlet-content-editable {
			@extend %display-portlet-content-editable;
		}

		> .portlet-topper {
			@extend %display-portlet-topper;
		}
	}
}

.portlet-content-editable {
	border-color: $portlet-topper-border;
	border-radius: $portlet-content-border-radius;
	border-style: solid;
	border-width: $portlet-content-border-width;
	padding: 10px;
}

/* ---------- Portlet topper ---------- */

.portlet-actions {
	float: right;
}

.portlet-name-text {
	font-size: 0.875rem;
	font-weight: 600;
}

.portlet-options {
	display: inline-block;
}

.portlet-title-default {
	@include flex(1 1 auto);
	@include text-overflow;

	line-height: 2;
}

.portlet-title-menu {
	@include flex(0 1 auto);

	> span > a {
		display: inline-block;
		text-decoration: none;
	}
}

.portlet-topper {
	background-color: $portlet-topper-bg;
	border-color: $portlet-topper-border;
	border-radius: $portlet-topper-border-radius;
	border-style: solid;
	border-width: $portlet-topper-border-width;
	color: $portlet-topper-color;

	@include display(flex);

	padding: 3px 12px 3px 24px;
	position: relative;
}

.portlet-topper-toolbar {
	margin: 0;
	padding-left: 0;

	> a, > span > a, .lfr-icon-menu > a {
		color: $portlet-topper-link-color;
	}

	> a {
		&:focus, &:hover {
			text-decoration: none;
		}
	}
}

/* ---------- Portlet controls in mobile ---------- */

.controls-visible {
	.portlet-content-editable {
		@include media-query(null, $screen-xs-max) {
			border-color: $portlet-topper-border;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	.portlet-topper {
		@include media-query(null, $screen-xs-max) {
			@include display(flex);
		}
	}

	.portlet-topper-toolbar {
		display: block;
	}
}