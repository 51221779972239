/* These inject tags are used for dynamically creating imports for themelet styles, you can place them where ever you like in this file. */

/* inject:imports */
/* endinject */

/* This file allows you to override default styles in one central location for easier upgrade and maintenance. */

@import "layout/custom_layout";

@import "partials/breadcrumb";
@import "partials/buttons";
@import "partials/dropdown_menu";
@import "partials/lists";
@import "partials/nav";
@import "partials/pagination";
@import "partials/popover";

@import "portlet/custom_portlet";

html, body {
	font-size: 62.5%;
	height: 100%;
}

// Styles pour les iframe 

body {
	&.portal-popup {
		#main-content,
		.form-control,
		.dropdown-menu {
			font-size: 1.6rem;
		}
		.toggle-switch-check:empty ~ .toggle-switch-bar, 
		label:not(.toggle-switch) .toggle-switch:empty ~ .toggle-switch-bar {
			font-size: 1.4rem;
		}
		select.form-control:not([size]):not([multiple]) {
			height: 4rem;
		}
		.form-control {
			height: 4rem;
		}
		.btn {
			font-size: 1.6rem;
		}
		.btn-monospaced {
			width: 4rem;
			height: 4rem;
		}
	}
}
#header-site .nav {
	flex-direction: row;
}