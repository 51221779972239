.loadingmask-message {
background: transparent;
border-width: 0;
display: block;
height: 1em;
margin-left: auto;
margin-right: auto;
position: relative;
text-align: left;
vertical-align: middle;
width: 1em;

	.loadingmask-message-content {
		animation: loading-animation 1.2s infinite ease-out;
		background: transparent;
		border-radius: 50%;
		border-width: 0;
		clear: both;
		color: transparent;
		height: 1em;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		transform: translateZ(0);
		width: 1em;
	}
}