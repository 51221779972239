.lfr-editable {
	$edit-color: #68BB30;
	$edit-color-hover: darken(#68BB30, 10);

	outline: 1px dashed $edit-color;
	outline-offset: 2px;
	position: relative;

	&:after {
		background: $edit-color url(../images/common/edit_white.png) no-repeat 50%;
		content: '';
		display: block;
		height: 20px;
		position: absolute;
		right: 5px;
		top: 5px;
		width: 30px;
	}

	&:focus {
		&:after {
			display: none;
		}

		&:hover {
			background-color: transparent;
		}
	}

	&:hover {
		background-color: rgba(255, 255, 255, 0.3);
		outline-color: $edit-color-hover;

		&:after {
			background-color: $edit-color-hover;
		}
	}
}

.lfr-editable-notice-close {
	margin-left: 1em;
}

.lfr-portlet-title-editable {
	margin-top: 0;
	z-index: 9999;

	.lfr-portlet-title-editable-content {
		padding: 0;

		.field-input {
			margin-bottom: 0;
		}

		.textfield-label {
			display: none;
		}

		.btn-toolbar-content {
			display: inline-block;
			vertical-align: bottom;
		}

		.btn-group {
			margin-top: 0;
		}

		.btn {
			display: inline-block;
			float: none;
			margin-top: 0;
			width: auto;
		}
	}
}