.user-icon-color-0 {
	background-color: $user-icon-bg-color-0;
	border: 1px solid $user-icon-border-color-0;
	color: $user-icon-color-0;
}

.user-icon-color-1 {
	background-color: $user-icon-bg-color-1;
	border: 1px solid $user-icon-border-color-1;
	color: $user-icon-color-1;
}

.user-icon-color-2 {
	background-color: $user-icon-bg-color-2;
	border: 1px solid $user-icon-border-color-2;
	color: $user-icon-color-2;
}

.user-icon-color-3 {
	background-color: $user-icon-bg-color-3;
	border: 1px solid $user-icon-border-color-3;
	color: $user-icon-color-3;
}

.user-icon-color-4 {
	background-color: $user-icon-bg-color-4;
	border: 1px solid $user-icon-border-color-4;
	color: $user-icon-color-4;
}

.user-icon-color-5 {
	background-color: $user-icon-bg-color-5;
	border: 1px solid $user-icon-border-color-5;
	color: $user-icon-color-5;
}

.user-icon-color-6 {
	background-color: $user-icon-bg-color-6;
	border: 1px solid $user-icon-border-color-6;
	color: $user-icon-color-6;
}

.user-icon-color-7 {
	background-color: $user-icon-bg-color-7;
	border: 1px solid $user-icon-border-color-7;
	color: $user-icon-color-7;
}

.user-icon-color-8 {
	background-color: $user-icon-bg-color-8;
	border: 1px solid $user-icon-border-color-8;
	color: $user-icon-color-8;
}

.user-icon-color-9 {
	background-color: $user-icon-bg-color-9;
	border: 1px solid $user-icon-border-color-9;
	color: $user-icon-color-9;
}