.cke_toolbar {
	&.cke_toolbar__a11yhelpbtn {
		.cke_toolgroup, .cke_toolgroup:hover {
			background: transparent;
		}

		.cke_button {
			&:hover {
				background: transparent;
			}

			.cke_button_label {
				&:after {
					border-color: #A6A6A6;
					font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
				}
			}
		}
	}
}