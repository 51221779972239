.form-options {
	border: 1px solid #AAA;
	display: inline-block;
	vertical-align: middle;

	input {
		border-width: 0;
		display: inline;
		float: left;
	}
}

.form-triggers {
	float: left;
}

.form-trigger {
	background: #C4C4C4 url(../images/application/button_bg.png) repeat-x 0 0;
	border-left: 1px solid #BBB;
	display: inline-block;
	float: left;

	img {
		background: no-repeat 50% 40%;
		height: 25px;
		padding: 0 3px;
		vertical-align: middle;
		width: 16px;
	}

	&:focus, &:hover {
		background-color: #83B0CA;
		background-image: url(../images/application/button_bg_over.png);
	}
}

.trigger-selected {
	background-color: #EBEBEB;
	background-image: url(../images/application/button_bg_selected.png);

	&:focus, &:hover {
		background-color: #B7DEF4;
		background-image: url(../images/application/button_bg_selected_over.png);
	}
}

.options-trigger img {
	background-image: url(../images/common/configuration.png);
}

.save-trigger img {
	background-image: url(../images/common/check.png);
}