.taglib-diff-addedline, #taglib-diff-results ins {
	background: #E6FFE6;
}

.taglib-diff-context {
	background: #EEE;
}

.taglib-diff-deletedline, #taglib-diff-results del {
	background: #FFE6E6;
}