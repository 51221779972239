/* ---------- Portlet topper ---------- */

$navbar-default-bg: #F7F7F7;
$navbar-default-border: transparent;
$navbar-default-color: $navbar-light-color;
$navbar-default-link-color: $navbar-light-color;
$navbar-default-link-hover-color: $navbar-light-hover-color;

$portlet-topper-bg: $navbar-default-bg !default;
$portlet-topper-border: $navbar-default-border !default;
$portlet-topper-color: $navbar-default-color !default;
$portlet-topper-link-color: $navbar-default-link-color !default;
$portlet-topper-link-hover-color: $navbar-default-link-hover-color !default;

$portlet-topper-border-radius: $navbar-border-radius $navbar-border-radius 0 0 !default;

$portlet-topper-border-bottom-width: 1px !default;
$portlet-topper-border-left-width: 1px !default;
$portlet-topper-border-right-width: 1px !default;
$portlet-topper-border-top-width: 1px !default;
$portlet-topper-border-width: $portlet-topper-border-top-width $portlet-topper-border-right-width $portlet-topper-border-bottom-width $portlet-topper-border-left-width !default;

$portlet-content-border-radius: 0 0 $navbar-border-radius $navbar-border-radius !default;

$portlet-content-border-bottom-width: 1px !default;
$portlet-content-border-left-width: 1px !default;
$portlet-content-border-right-width: 1px !default;
$portlet-content-border-top-width: 1px !default;
$portlet-content-border-width: $portlet-content-border-top-width $portlet-content-border-right-width $portlet-content-border-bottom-width $portlet-content-border-left-width !default;

/* ---------- Portlet decorator ---------- */

$portlet-decorate-bg: $body-bg !default;
$portlet-decorate-border-color: $navbar-default-border !default;
$portlet-decorate-border-style: solid !default;
$portlet-decorate-border-width: $portlet-content-border-width !default;

/* ---------- Portlet header ---------- */

$portlet-header-margin-bottom: map-get($spacers, 3) !default;