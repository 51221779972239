.ib4.d2-0 {
	ul {
		list-style-type: none;
		padding-left: 13px;
		margin-top: 0;
		margin-bottom: 10px;

		&.list-unstyled {
			> li:before {
				display: none !important;
			}
		}

		> li {
			background-image: none;
			background-position: left 0.9rem;
			margin-top: 0.6rem;
			position: relative;
			padding-left: 1.5rem;

			&:before {
				content: "";
				width: 0.6rem;
				height: 0.6rem;
				background-color: #595959;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: 0.9rem;
				left: 0;
			}
		}
	}

	.list-inline {
		li {
			display: inline-block;
		}
	}
}