.ib4.d2-0 {
	ul.pagination {
		padding: 0;

		li {
			padding-left: 0;

			a {
				align-items: center;
				display: flex;
				height: 4.4rem;
				justify-content: center;
				min-width: 4.4rem;
				text-decoration: none;
			}
		}

		li:before {
			display: none;
		}
	}
}