.quick-access-nav {
	position: absolute;
	top: -1000px;
	width: 100%;
	z-index: 1000;

	a {
		background: #333;
		background: rgba(51, 51, 51, 0.9);
		border: 1px solid #FFF;
		left: 0;
		outline: none;
		padding: 8px;
		position: absolute;
		right: 0;
		text-align: center;

		&:focus {
			color: #FFF;
			top: 1000px;
		}
	}
}