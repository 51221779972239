.tagselector-content {
	border-width: 0;
	padding: 0;

	.textboxlistentry-holder {
		border-bottom: 2px solid #869CAD;
		margin-bottom: 10px;
		padding: 0;
	}
}

.categoriesselector-content .textboxlistentry-holder {
	background-color: transparent;
	border-width: 0;
	padding: 0;
}