.portlet-decorate .portlet-content {
	background: $portlet-decorate-bg;
	border-color: $portlet-decorate-border-color;
	border-style: $portlet-decorate-border-style;
	border-width: $portlet-decorate-border-width;
	word-wrap: break-word;
}

.portlet-barebone .portlet-content {
	padding: 0;
}