.ib4.d2-0 {
	.breadcrumb {
		font-size: 1.4rem;
		line-height: 1.5rem;

		.breadcrumb-item.accueil a:before, .breadcrumb-item:only-child:before {
			width: 1.6rem;
			height: 1.3rem;
		}

		.breadcrumb-item + .breadcrumb-item::before {
			background: none;
			color: #cbcccc;
			content: ">";
			display: inline-block;
			float: none;
			padding-right: 0.25rem;
			left: unset;
			margin-top: 0;
			position: relative;
			top: unset;
			width: unset;
		}

	}
}