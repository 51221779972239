.lfr-panel.lfr-extended, .lfr-panel-container, .lfr-floating-container {
	border-color: #DEDEDE #BFBFBF #BFBFBF #DEDEDE;
}

.lfr-panel-container, .lfr-floating-container {
	background: #FFF;
}

.lfr-panel-titlebar {
	.lfr-panel-title {
		font-size: 1.2em;
		font-weight: bold;
	}

	.lfr-panel-button {
		background: url(../images/application/panel_header_toggler_close.png) no-repeat 0 0;

		&:hover {
			background-position: 0 100%;
		}
	}
}

.lfr-panel{
	&.lfr-collapsible .lfr-panel-titlebar {
		background: url(../images/arrows/05_down.png) no-repeat 2px 50%;
	}

	&.lfr-collapsed .lfr-panel-titlebar {
		background-image: url(../images/arrows/05_right.png);

		.lfr-panel-button {
			background-image: url(../images/application/panel_header_toggler_open.png);
		}
	}

	&.lfr-extended .lfr-panel-titlebar {
		background: #D6D6D6 url(../images/application/panel_header.png) repeat-x 0 0;
		border-bottom-color: #CDCDCD;
	}
}

.lfr-panel-container .lfr-extended.lfr-collapsible .lfr-panel-titlebar {
	border-top-color: #CECECE;
}

.js {
	.lfr-floating-trigger {
		background-image: url(../images/arrows/05_down.png);
		background-position: 100% 50%;
		background-repeat: no-repeat;
		text-decoration: none;
	}
}