.lfr-form-row {
	border-bottom-color: #CCC;
}

.lfr-form-row:hover {
	border-bottom-color: #CCC;
}

.lfr-form-row .handle-sort-vertical {
	background: url(../images/application/handle_sort_vertical.png) no-repeat 0 50%;
}