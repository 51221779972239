.file-icon-color-0 {
	background-color: $file-icon-color-0;
	color: $file-icon-text-color-0;
}

.file-icon-color-1 {
	background-color: $file-icon-color-1;
	color:$file-icon-text-color-1;
}

.file-icon-color-2 {
	background-color: $file-icon-color-2;
	color:$file-icon-text-color-2;
}

.file-icon-color-3 {
	background-color: $file-icon-color-3;
	color:$file-icon-text-color-3;
}

.file-icon-color-4 {
	background-color: $file-icon-color-4;
	color:$file-icon-text-color-4;
}

.file-icon-color-5 {
	background-color: $file-icon-color-5;
	color:$file-icon-text-color-5;
}

.file-icon-color-6 {
	background-color: $file-icon-color-6;
	color:$file-icon-text-color-6;
}

.file-icon-color-7 {
	background-color: $file-icon-color-7;
	color:$file-icon-text-color-7;
}