// Compatibility layer components config

$compat-alerts: true !default;
$compat-basic_search: true !default;
$compat-breadcrumbs: true !default;
$compat-button_groups: true !default;
$compat-buttons: true !default;
$compat-cards: true !default;
$compat-component_animations: true !default;
$compat-dropdowns: true !default;
$compat-figures: true !default;
$compat-form_validation: true !default;
$compat-forms: true !default;
$compat-grid: true !default;
$compat-icons: true !default;
$compat-labels: true !default;
$compat-liferay: true !default;
$compat-list_groups: true !default;
$compat-management_bar: true !default;
$compat-modals: true !default;
$compat-nav_tabs: true !default;
$compat-navbar: true !default;
$compat-navs: true !default;
$compat-pager: true !default;
$compat-pagination: true !default;
$compat-panels: true !default;
$compat-progress_bars: true !default;
$compat-responsive_utilities: true !default;
$compat-sidebar: true !default;
$compat-simple_flexbox_grid: true !default;
$compat-stickers: true !default;
$compat-tables: true !default;
$compat-toggle_card: true !default;
$compat-toggle_switch: true !default;
$compat-toolbar: true !default;
$compat-user_icons: true !default;
$compat-utilities: true !default;

$compat-atlas-focus-styles: true !default;